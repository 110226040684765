import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import { UserConsentFragment, UserConsentsDocument } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { getDiffFromNow } from "../utils/date";
import { t } from "../utils/i18n";
import { getConsentPurpose, getDiffAgoLabel } from "../utils/templateTranslations";
import { ConsentStatusTag } from "./BusinessTags";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";

const PER_PAGE = 20;

type ExtraInfo = { projectEnv: ProjectEnv; projectId: string };

const keyExtractor = (node: UserConsentFragment) => node.id;

const defaultFixedColumns: ColumnConfig<UserConsentFragment, ExtraInfo>[] = [
  {
    id: "purpose",
    width: 400,
    title: t("user.details.consents.table.purpose"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => {
      const purpose = getConsentPurpose(item.purpose);
      return <TextCell variant="medium" text={purpose} />;
    },
  },
];

const defaultActiveColumns: ColumnConfig<UserConsentFragment, ExtraInfo>[] = [
  {
    id: "status",
    width: 120,
    title: t("user.details.cards.table.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { status } }) => (
      <Cell>
        <ConsentStatusTag status={status} />
      </Cell>
    ),
  },
  {
    id: "id",
    width: 400,
    title: t("user.details.consents.table.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => {
      return (
        <CopyableTextCell
          text={item.id}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      );
    },
  },
  {
    id: "lastUpdate",
    width: 130,
    title: t("user.details.consents.table.lastUpdate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { updatedAt } }) => {
      if (isNotNullish(updatedAt)) {
        const diff = getDiffFromNow(dayjs(updatedAt));

        return (
          <LakeTooltip content={dayjs(updatedAt).format("LLL")} containerStyle={commonStyles.fill}>
            <TextCell variant="smallRegular" text={getDiffAgoLabel(diff)} />
          </LakeTooltip>
        );
      }

      return null;
    },
  },
];

type Props = {
  userId: string;
};

export const UserDetailConsents = ({ userId }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  const [data, { isLoading, reload, setVariables }] = useQuery(UserConsentsDocument, {
    userId,
    first: PER_PAGE,
  });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const columns = useColumnChooser("User>Consents", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo = useMemo(
    () => ({
      projectEnv,
      projectId,
    }),
    [projectEnv, projectId],
  );

  return (
    <>
      <Box direction="row" alignItems="center">
        <ColumnChooser {...columns} />
        <Space width={8} />

        <LakeButton
          ariaLabel={t("common.refresh")}
          mode="secondary"
          size="small"
          icon="arrow-counterclockwise-filled"
          loading={isRefreshing}
          onPress={() => {
            setIsRefreshing(true);
            reload().tap(() => setIsRefreshing(false));
          }}
        />
      </Box>

      <Space height={12} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.consents}>
            {consents => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={consents?.edges.map(edge => edge.node) ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                onEndReached={() => {
                  if (consents?.pageInfo.hasNextPage === true) {
                    setVariables({ after: consents?.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={48}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() => (
                  <EmptyView icon="lake-inbox-empty" title={t("common.list.noResults")} />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
