import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { ColumnConfig, VirtualizedList } from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { match } from "ts-pattern";
import { UltimateBeneficialOwnersFragment } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { BooleanTag } from "./BooleanTag";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  reexecuteQuery: () => void;
};

type Edge = UltimateBeneficialOwnersFragment;

const keyExtractor = (_: Edge, index: number) => String(index);

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 500,
    id: "name",
    title: t("ultimateBeneficialOwner.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { firstName, lastName } }) =>
      isNotNullish(firstName) && isNotNullish(lastName) ? (
        <TextCell variant="medium" text={`${firstName} ${lastName}`} />
      ) : null,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 190,
    id: "type",
    title: t("ultimateBeneficialOwner.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        info: { type },
      },
    }) => (
      <Cell>
        {match(type)
          .with("LegalRepresentative", value => <Tag color="darkPink">{value}</Tag>)
          .with("HasCapital", value => <Tag color="live">{value}</Tag>)
          .with("Other", value => <Tag color="gray">{value}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 150,
    id: "birthDate",
    title: t("ultimateBeneficialOwner.birthDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { birthDate } }) =>
      isNotNullish(birthDate) ? (
        <TextCell text={dayjs(birthDate).format(locale.dateFormat)} />
      ) : null,
  },
  {
    width: 170,
    id: "birthPostalCode",
    title: t("ultimateBeneficialOwner.birthPostalCode"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { birthCityPostalCode } }) =>
      isNotNullish(birthCityPostalCode) ? <TextCell text={birthCityPostalCode} /> : null,
  },
  {
    width: 200,
    id: "birthCity",
    title: t("ultimateBeneficialOwner.birthCity"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { birthCity } }) =>
      isNotNullish(birthCity) ? <TextCell text={birthCity} /> : null,
  },
  {
    width: 150,
    id: "birthCountry",
    title: t("ultimateBeneficialOwner.birthCountry"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { birthCountryCode } }) =>
      isNotNullish(birthCountryCode) ? <TextCell text={birthCountryCode} /> : null,
  },
  {
    width: 200,
    id: "indirectOwnership",
    title: t("ultimateBeneficialOwner.indirectOwnership"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { info } }) => (
      <Cell>
        {match(info)
          .with(
            { __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" },
            ({ indirect }) => {
              if (isNotNullish(indirect)) {
                return <BooleanTag value={indirect} />;
              }
              return null;
            },
          )
          .otherwise(() => null)}
      </Cell>
    ),
  },
  {
    width: 180,
    id: "directOwnership",
    title: t("ultimateBeneficialOwner.directOwnership"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { info } }) => (
      <Cell>
        {match(info)
          .with({ __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" }, ({ direct }) => {
            if (isNotNullish(direct)) {
              return <BooleanTag value={direct} />;
            }
            return null;
          })
          .otherwise(() => null)}
      </Cell>
    ),
  },
  {
    width: 150,
    id: "capitalHeld",
    title: t("ultimateBeneficialOwner.capitalHeld"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { info } }) =>
      match(info)
        .with(
          { __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" },
          ({ totalCapitalPercentage }) => {
            if (isNotNullish(totalCapitalPercentage)) {
              return <TextCell text={`${totalCapitalPercentage}%`} />;
            }
            return null;
          },
        )
        .otherwise(() => null),
  },
];

type Props = {
  columnChooserId: string;
  ultimateBeneficialOwners: Array<Edge>;
  extraInfo: ExtraInfo;
  emptyListTitle: string;
};

export const UltimateBeneficialOwnerList = ({
  columnChooserId,
  ultimateBeneficialOwners,
  extraInfo,
  emptyListTitle,
}: Props) => {
  const columns = useColumnChooser(columnChooserId, {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  return (
    <>
      <Box direction="row" alignItems="center">
        <ColumnChooser {...columns} />
      </Box>

      <Space height={12} />

      <VirtualizedList
        variant="default"
        marginHorizontal={negativeSpacings[24]}
        extraInfo={extraInfo}
        keyExtractor={keyExtractor}
        data={ultimateBeneficialOwners}
        stickedToStartColumns={columns.fixed}
        columns={columns.active}
        headerHeight={48}
        rowHeight={48}
        renderEmptyList={() => <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />}
      />
    </>
  );
};
