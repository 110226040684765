import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  AccountListFragment,
  AccountOrderByFieldInput,
  AccountOrderByInput,
  GetAccountHolderAccountsDocument,
  OrderByDirection,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { AccountList, accountListDefaultColumns, ExtraInfo } from "./AccountList";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { TrackPressable } from "./TrackPressable";

type Props = {
  accountHolderId: string;
  accountHolderName: string;
  params: RouteParams<"HoldersDetailAccounts">;
};

const PER_PAGE = 20;

export const AccountHolderDetailAccounts = ({
  accountHolderId,
  accountHolderName,
  params,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const sortBy: AccountOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountHolderAccountsDocument, {
    id: accountHolderId,
    first: PER_PAGE,
    orderBy: sortBy,
  });

  const columns = useColumnChooser("AccountHolder>Accounts", {
    defaultFixedColumns: accountListDefaultColumns.fixed,
    defaultActiveColumns: accountListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("HoldersDetailAccounts", {
          projectId,
          projectEnv,
          accountHolderId,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: () => {},
    };
  }, [projectEnv, projectId, accountHolderId, sortBy]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<AccountListFragment>, ExtraInfo>) => (
      <Link to={Router.AccountDetailRoot({ projectId, projectEnv, accountId: id })} />
    ),
    [],
  );

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ accountHolder }) => Option.fromNullable(accountHolder?.accounts.totalCount));

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh accounts list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              reload().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        {totalCount
          .map<ReactNode>(count => (
            <Tag key="account-tag" size="large" color="partner">
              {t("account.counter", { count: count ?? 0 })}
            </Tag>
          ))
          .toNull()}
      </Box>

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data?.accountHolder?.accounts}>
            {accounts => (
              <AccountList
                accounts={accounts?.edges ?? []}
                columns={columns}
                onEndReached={() => {
                  if (accounts?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: accounts?.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("accountHolder.accounts.empty", { name: accountHolderName })}
                getRowLink={getRowLink}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
