import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { P, isMatching, match } from "ts-pattern";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  CapitalDepositCaseOrderByInput,
  CapitalDepositCaseStatus,
  CapitalDepositsListFragment,
  GetCapitalDepositsListDocument,
  OrderByDirection,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, formatCurrency, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Edge = GetEdge<CapitalDepositsListFragment>;

const keyExtractor = ({ node: { id } }: Edge) => id;

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 240,
    id: "companyName",
    title: t("capitalDeposit.companyName"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyName },
      },
    }) => <TextCell variant="medium" text={companyName} />,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("capitalDeposit.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 270,
    id: "status",
    title: t("capitalDeposit.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { status },
      },
    }) => (
      <Cell>
        {match(status)
          .with("WaitingForRequirements", () => (
            <Tag color="gray">{t("capitalDeposit.status.waitingForRequirements")}</Tag>
          ))
          .with("WaitingForRegisterExtract", () => (
            <Tag color="shakespear">{t("capitalDeposit.status.waitingForRegisterExtract")}</Tag>
          ))
          .with("Initiated", () => (
            <Tag color="negative">{t("capitalDeposit.status.initiated")}</Tag>
          ))
          .with("Canceled", () => <Tag color="positive">{t("capitalDeposit.status.canceled")}</Tag>)
          .with("Completed", () => (
            <Tag color="positive">{t("capitalDeposit.status.completed")}</Tag>
          ))
          .with("WaitingForShareDepositCertificate", () => (
            <Tag color="warning">
              {t("capitalDeposit.status.waitingForShareDepositCertificate")}
            </Tag>
          ))
          .with("WaitingForNotaryTransfer", () => (
            <Tag color="warning">{t("capitalDeposit.status.waitingForNotaryTransfer")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 250,
    id: "companyOnboarding",
    title: t("capitalDeposit.companyOnboardingId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyOnboarding },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(companyOnboarding)) {
        const onboardingId = companyOnboarding.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("OnboardingDetailRoot", { projectId, projectEnv, onboardingId })
            }
          >
            {companyOnboarding.id}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 250,
    id: "companyAccountId",
    title: t("capitalDeposit.companyAccountId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { companyAccountId },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(companyAccountId)) {
        return (
          <LinkCell
            onPress={() =>
              Router.push("AccountDetailRoot", {
                projectId,
                projectEnv,
                accountId: companyAccountId,
              })
            }
          >
            {companyAccountId}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 240,
    id: "amount",
    title: t("capitalDeposit.amount"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { totalCapitalDepositAmount },
      },
    }) => (
      <TextCell
        text={
          totalCapitalDepositAmount.value !== ""
            ? formatCurrency(
                Number(totalCapitalDepositAmount.value),
                totalCapitalDepositAmount.currency,
              )
            : "-"
        }
      />
    ),
  },
  {
    width: 200,
    id: "uploadedDocuments",
    title: t("capitalDeposit.uploadedDocuments"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { documents },
      },
    }) => {
      let numberOfValidatedDocuments = 0;
      documents.map(document => {
        if (
          document.statusInfo.status === "Validated" ||
          document.statusInfo.status === "Uploaded"
        ) {
          ++numberOfValidatedDocuments;
        }
      });
      return <TextCell text={String(numberOfValidatedDocuments)} />;
    },
  },
  {
    width: 200,
    id: "createdAt",
    title: t("capitalDeposit.createdAt"),
    renderTitle: ({ title, extraInfo }) => {
      return (
        <TrackPressable action="Sort capital deposit by creation date">
          <HeaderCell
            text={title}
            onPress={direction => {
              extraInfo.onChangeSort?.({ field: "createdAt", direction });
            }}
            sort={
              extraInfo.sortBy?.field === "createdAt"
                ? (extraInfo.sortBy?.direction ?? undefined)
                : undefined
            }
          />
        </TrackPressable>
      );
    },
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("capitalDeposit.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort capital deposit by update date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
];

const statusFilter: FilterCheckboxDef<CapitalDepositCaseStatus> = {
  type: "checkbox",
  label: t("accountHolder.type"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Completed", label: t("capitalDeposit.type.completed") },
    { value: "Initiated", label: t("capitalDeposit.type.initiated") },
    { value: "WaitingForNotaryTransfer", label: t("capitalDeposit.type.waitingForNotaryTransfer") },
    {
      value: "WaitingForRegisterExtract",
      label: t("capitalDeposit.type.waitingForRegisterExtract"),
    },
    { value: "WaitingForRequirements", label: t("capitalDeposit.type.waitingForRequirements") },
    {
      value: "WaitingForShareDepositCertificate",
      label: t("capitalDeposit.type.waitingForShareDepositCertificate"),
    },
  ],
};

const filtersDefinition = {
  status: statusFilter,
};

type CapitalDepositFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: CapitalDepositFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: CapitalDepositFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const availableFilters: { name: keyof CapitalDepositFilters; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("capitalDeposit.status"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh capital deposit filters list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            loading={isRefreshing}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};

type Props = {
  params: RouteParams<"CapitalDepositList">;
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: CapitalDepositCaseOrderByInput) => void;
  sortBy?: CapitalDepositCaseOrderByInput;
  reexecuteQuery: () => void;
};

const PER_PAGE = 20;

const statusList = deriveUnion<CapitalDepositCaseStatus>({
  Canceled: true,
  Completed: true,
  Initiated: true,
  WaitingForNotaryTransfer: true,
  WaitingForRegisterExtract: true,
  WaitingForRequirements: true,
  WaitingForShareDepositCertificate: true,
});

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<GetEdge<CapitalDepositsListFragment>, ExtraInfo>) => (
  <Link to={Router.CapitalDepositDetailRoot({ projectId, projectEnv, capitalDepositId: id })} />
);

const isStringList = isMatching(P.array(P.string));

export const CapitalDepositListPage = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const serializedStatus = JSON.stringify(params.status ?? null);

  const filters = useMemo(() => {
    const status = JSON.parse(serializedStatus) as unknown;

    return {
      status: isStringList(status)
        ? Array.filterMap(status, item => (statusList.is(item) ? Option.Some(item) : Option.None()))
        : undefined,
    } as const;
  }, [serializedStatus]);

  const sortBy: CapitalDepositCaseOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<CapitalDepositCaseOrderByInput["field"]>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetCapitalDepositsListDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const columns = useColumnChooser("CapitalDeposits", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("CapitalDepositList", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, sortBy, reload, params]);

  const capitalDeposit = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ capitalDepositCases }) => capitalDepositCases);

  const totalCount = capitalDeposit.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("CapitalDepositList", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("CapitalDepositList", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.capitalDepositCases}>
            {capitalDepositCases => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                extraInfo={extraInfo}
                keyExtractor={keyExtractor}
                data={capitalDepositCases.edges}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                onEndReached={() => {
                  if (capitalDepositCases.pageInfo.hasNextPage === true) {
                    setVariables({ after: capitalDepositCases.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={48}
                getRowLink={getRowLink}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                renderEmptyList={() =>
                  hasSearchOrFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView
                      icon="lake-inbox-empty"
                      title={t("capitalDeposit.list.empty.title")}
                      subtitle={t("capitalDeposit.list.empty.subtitle")}
                    />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
