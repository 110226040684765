import { Box } from "@swan-io/lake/src/components/Box";
import { Path, Rect, Svg } from "@swan-io/lake/src/components/Svg";
import { colors } from "@swan-io/lake/src/constants/design";
import { StyleSheet, Text } from "react-native";

type Props = {
  text: string;
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: "auto",
    width: "40%",
  },
  textContainer: {
    backgroundColor: colors.gray[100],
    position: "absolute",
    marginTop: 85,
    marginLeft: 28,
    marginRight: 60,
    padding: 12,
    borderRadius: 12,
  },
  text: {
    color: colors.gray[900],
    fontSize: 12,
    lineHeight: 16,
  },
  bubble: { position: "absolute", left: -5, bottom: 0 },
});

export const NotificationsPhonePreview = ({ text }: Props) => {
  return (
    <Box style={styles.container}>
      <Svg viewBox="0 0 334 650">
        {/* Header */}
        <Rect width={252} height={18} x={41} y={68} fill="#EDEDED" rx={4} />
        <Rect width={54} height={10} x={41} y={40} fill="#EDEDED" rx={4} />
        <Rect width={18} height={10} x={275} y={40} fill="#EDEDED" rx={4} />
        <Rect width={18} height={10} x={247} y={40} fill="#EDEDED" rx={4} />
        <Path fill="#E8E7E8" d="M17 99h300v1H17z" />

        <Path
          fill="#737276"
          d="M140 45a5 5 0 0 1 5-5h26a5 5 0 0 1 0 10h-26a5 5 0 0 1-5-5Zm44 0a5 5 0 0 1 10 0 5 5 0 0 1-10 0Z"
        />

        {/* Phone frame */}
        <Path
          fill="#000"
          d="M278.84 0a51.37 51.37 0 0 1 51.38 51.4v133.77a3.78 3.78 0 0 1 3.78 3.78v72.56a3.78 3.78 0 0 1-3.78 3.78V598.6a51.4 51.4 0 0 1-51.38 51.4H53.65a51.37 51.37 0 0 1-51.38-51.4V276.34A3.78 3.78 0 0 1 0 272.85V229a3.78 3.78 0 0 1 2.27-3.47v-12.7A3.78 3.78 0 0 1 0 209.36v-43.84a3.78 3.78 0 0 1 2.27-3.47v-23.28A3.78 3.78 0 0 1 0 135.29v-18.14a3.78 3.78 0 0 1 2.27-3.48V51.4A51.4 51.4 0 0 1 53.65 0h225.19Zm0 15.87H53.65A34.76 34.76 0 0 0 18.9 50.03v548.58a34.77 34.77 0 0 0 34.16 34.76h225.79a34.76 34.76 0 0 0 34.76-34.16V50.64a34.75 34.75 0 0 0-34.76-34.77Z"
        />
      </Svg>

      <Box style={styles.textContainer}>
        <Text style={styles.text}>{text}</Text>

        <Svg width={20} height={14} style={styles.bubble}>
          <Path
            fill="#e8e7e8"
            fillRule="evenodd"
            d="M0 13.672V14h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 0 1 0 13.672z"
          />
        </Svg>
      </Box>
    </Box>
  );
};
