import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { TransitionView } from "@swan-io/lake/src/components/TransitionView";
import { animations, colors, spacings } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import welcome from "../assets/img/activate/welcome.svg";
import { formatNestedMessage, t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  illustrationContainer: {
    aspectRatio: 3 / 1,
    marginTop: -48,
    marginLeft: -48,
    marginRight: -48,
    marginBottom: spacings[24],
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: "hidden",
  },
  illustrationBackground: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0.12,
    backgroundImage:
      "linear-gradient(180deg, #8266C4 0%, rgba(217, 97, 81, 0.5) 70.75%), linear-gradient(90deg, #6240b5, #6240b500)",
    backgroundSize: "100% 100%, 50px 100%",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  illustrationBackgroundLeftImage: {
    position: "absolute",
    top: "10%",
    bottom: "-90%",
    left: spacings[32],
    width: 200,
    transform: "translateZ(0px)",
  },
  illustrationBackgroundRightImage: {
    position: "absolute",
    bottom: "-20%",
    top: "-50%",
    right: spacings[32],
    width: 200,
    transform: "translateZ(0px)",
  },
  illustrationImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  followingStep: {
    ...animations.fadeAndSlideInFromRight.enter,
  },
});

type Props = {
  fullName?: string;
  visible: boolean;
  onPressTalkToAndExpert: () => void;
  onDismiss: () => void;
};

export const FirstVisitModal = ({
  fullName,
  onPressTalkToAndExpert,
  onDismiss,
  visible,
}: Props) => {
  const [step, setStep] = useState<1 | 2>(1);

  return (
    <LakeModal visible={visible}>
      <View style={styles.illustrationContainer}>
        <View style={styles.illustrationBackground} />

        <TransitionView
          {...animations.fadeAndSlideInFromBottom}
          style={styles.illustrationBackgroundLeftImage}
        >
          <Image
            source={{
              uri: welcome,
            }}
            resizeMode="contain"
            style={styles.illustrationImage}
          />
        </TransitionView>

        <TransitionView
          {...animations.fadeAndSlideInFromTop}
          style={styles.illustrationBackgroundRightImage}
        >
          <Image
            source={{
              uri: welcome,
            }}
            resizeMode="contain"
            style={styles.illustrationImage}
          />
        </TransitionView>
      </View>

      <LakeHeading level={2} variant="h3">
        {t("firstVisitModal.welcomeToSwan")}
      </LakeHeading>

      <Space height={12} />

      {match(step)
        .with(1, () => (
          <>
            <LakeText>
              {fullName != null
                ? t("firstVisitModal.introduction", { fullName })
                : t("firstVisitModal.introductionNoName")}
            </LakeText>

            <Space height={16} />

            <LakeButtonGroup paddingBottom={0}>
              <TrackPressable action="Press ready to build">
                <LakeButton grow={true} color="current" onPress={() => setStep(2)}>
                  {t("firstVisitModal.readyToBuild")}
                </LakeButton>
              </TrackPressable>
            </LakeButtonGroup>
          </>
        ))
        .with(2, () => (
          <View style={styles.followingStep}>
            <LakeText>
              {formatNestedMessage("firstVisitModal.compliance", {
                followsTheRulesLink: (
                  <LakeText
                    color={colors.current[500]}
                    href="https://support.swan.io/hc/en-150/articles/5767255782045-Restricted-businesses"
                    hrefAttrs={{ target: "blank" }}
                  >
                    {t("firstVisitModal.followsTheRulesLink")}
                  </LakeText>
                ),
                makeSureStrongText: t("firstVisitModal.makeSureStrongText"),
              })}
            </LakeText>

            <Space height={16} />

            <LakeButtonGroup paddingBottom={0}>
              <TrackPressable action="Skip first visit modal">
                <LakeButton mode="secondary" grow={true} onPress={onDismiss}>
                  {t("firstVisitModal.skip")}
                </LakeButton>
              </TrackPressable>

              <TrackPressable action="Talk to an expert">
                <LakeButton
                  icon="person-call-filled"
                  grow={true}
                  color="current"
                  onPress={onPressTalkToAndExpert}
                >
                  {t("firstVisitModal.talkToAnExpert")}
                </LakeButton>
              </TrackPressable>
            </LakeButtonGroup>
          </View>
        ))
        .exhaustive()}
    </LakeModal>
  );
};
