import { AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import dayjs from "dayjs";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import { GetAccountStatementDocument, GetAccountStatementQuery } from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { NewAccountStatementPage } from "../pages/NewAccountStatementPage";
import { formatCurrency, locale, t } from "../utils/i18n";
import { RouteParams, Router, accountDetailRoutes } from "../utils/routes";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type Edge = GetEdge<NonNullable<NonNullable<GetAccountStatementQuery["account"]>["statements"]>>;

const keyExtractor = ({ node: { id } }: Edge) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 180,
    id: "openingDate",
    title: t("accountStatements.openingDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { openingDate },
      },
    }) => {
      return (
        <TextCell text={dayjs(openingDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      );
    },
  },
  {
    width: 180,
    id: "closingDate",
    title: t("accountStatements.closingDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { closingDate },
      },
    }) => {
      return (
        <TextCell text={dayjs(closingDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      );
    },
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 370,
    id: "id",
    title: t("accountStatements.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => {
      return (
        <CopyableTextCell
          text={id}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      );
    },
  },
  {
    width: 110,
    id: "status",
    title: t("accountStatements.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { status },
      },
    }) => {
      return (
        <Cell>
          {match(status)
            .with("Available", () => (
              <Tag color="positive">{t("accountStatements.status.available")}</Tag>
            ))
            .with("Failed", () => (
              <Tag color="negative">{t("accountStatements.status.failed")}</Tag>
            ))
            .with("Pending", () => (
              <Tag color="shakespear">{t("accountStatements.status.pending")}</Tag>
            ))
            .exhaustive()}
        </Cell>
      );
    },
  },
  {
    width: 200,
    id: "openingBalance",
    title: t("accountStatements.openingBalance"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { openingBalance },
      },
    }) => {
      return (
        <BalanceCell
          currency={openingBalance.currency}
          value={Number(openingBalance.value)}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 200,
    id: "closingBalance",
    title: t("accountStatements.closingBalance"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { closingBalance },
      },
    }) => {
      return (
        <BalanceCell
          currency={closingBalance.currency}
          value={Number(closingBalance.value)}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 170,
    id: "totalDebit",
    title: t("accountStatements.totalDebit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { totalDebits },
      },
    }) => {
      return (
        <BalanceCell
          currency={totalDebits.currency}
          value={Number(totalDebits.value)}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 150,
    id: "totalCredit",
    title: t("accountStatements.totalCredit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { totalCredits },
      },
    }) => {
      return (
        <BalanceCell
          currency={totalCredits.currency}
          value={Number(totalCredits.value)}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 110,
    id: "fees",
    title: t("accountStatements.fees"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { fees },
      },
    }) => {
      return (
        <BalanceCell
          currency={fees.currency}
          value={Number(fees.value)}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 180,
    id: "createdAt",
    title: t("accountStatements.creationDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => {
      return (
        <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      );
    },
  },
  {
    width: 180,
    id: "updatedAt",
    title: t("accountStatements.updateDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => {
      return (
        <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      );
    },
  },
];

const endColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 40,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({
      item: {
        node: { type, status },
      },
    }) => {
      const url = match(type[0])
        .with({ __typename: "PdfStatement" }, { __typename: "CsvStatement" }, ({ url }) => url)
        .otherwise(() => null);

      if (isNullish(url)) {
        return null;
      }

      return (
        <Cell>
          {status === "Available" && (
            <Link target="blank" to={url} download={true}>
              <Icon size={16} name="arrow-download-filled" />
            </Link>
          )}
        </Cell>
      );
    },
  },
];

const PER_PAGE = 20;

type Props = {
  accountId: string;
  params: RouteParams<"AccountDetailAccountStatementsRoot">;
};

export const AccountDetailAccountStatements = ({ accountId, params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canCreateAccountStatements = usePermissions(projectEnv).dataAccount.write;

  const route = Router.useRoute(accountDetailRoutes);

  const filters: AccountStatementsFilters = useMemo(
    () => ({
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
    }),
    [params.isAfterUpdatedAt, params.isBeforeUpdatedAt],
  );

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountStatementDocument, {
    accountId,
    first: PER_PAGE,
    filters,
  });

  const columns = useColumnChooser("Account>Statements", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId };
  }, [projectEnv, projectId]);

  const openNew = useCallback(() => {
    Router.push("AccountDetailAccountStatementsNew", { projectId, projectEnv, accountId });
  }, [projectEnv, projectId, accountId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ account }) => Option.fromNullable(account?.statements?.totalCount));

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        totalCount={totalCount}
        onRefresh={reload}
        openNew={openNew}
        canCreateAccountStatements={canCreateAccountStatements}
        onChangeFilters={filters =>
          Router.replace("AccountDetailAccountStatementsRoot", {
            projectId,
            projectEnv,
            accountId,
            ...filters,
          })
        }
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.account?.statements}>
            {statements => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={statements?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                stickedToEndColumns={endColumns}
                onEndReached={() => {
                  if (statements?.pageInfo.hasNextPage === true) {
                    setVariables({ after: statements?.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={48}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() =>
                  hasFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView icon="lake-inbox-empty" title={t("common.list.noResults")} />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}

      {match(route)
        .with({ name: "AccountDetailAccountStatementsNew" }, () => (
          <LakeModal
            maxWidth={700}
            visible={true}
            title={t("accountStatements.new.title")}
            icon="add-circle-regular"
          >
            {match(data.mapOk(data => data.account))
              .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
              .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
                <ErrorView error={error} />
              ))
              .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <ErrorView />)
              .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), account => (
                <NewAccountStatementPage
                  creationDate={account.createdAt}
                  accountId={accountId}
                  canCreateAccountStatements={canCreateAccountStatements}
                  onSave={() => {
                    reload();

                    Router.push("AccountDetailAccountStatementsRoot", {
                      projectId,
                      projectEnv,
                      accountId,
                    });
                  }}
                />
              ))
              .exhaustive()}
          </LakeModal>
        ))
        .otherwise(() => null)}
    </>
  );
};

const isAfterUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isAfterUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isAfterUpdatedAtSelectable,
  validate: validateAfterUpdatedAt,
};

const isBeforeUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isBeforeUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isBeforeUpdatedAtSelectable,
  validate: validateBeforeUpdatedAt,
};

const filtersDefinition = {
  isAfterUpdatedAt: isAfterUpdatedAtFilter,
  isBeforeUpdatedAt: isBeforeUpdatedAtFilter,
};

type AccountStatementsFilters = FiltersState<typeof filtersDefinition>;

type AccountStatementsFiltersProps = {
  filters: AccountStatementsFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  totalCount: Option<number>;
  onRefresh: () => Future<unknown>;
  openNew: () => void;
  onChangeFilters: (filters: AccountStatementsFilters) => void;
  canCreateAccountStatements: boolean;
};

const FiltersForm = ({
  filters,
  columns,
  onChangeFilters,
  openNew,
  totalCount,
  onRefresh,
  canCreateAccountStatements,
}: AccountStatementsFiltersProps) => {
  const availableFilters: { name: keyof AccountStatementsFilters; label: string }[] = [
    {
      name: "isAfterUpdatedAt",
      label: t("transaction.filters.isAfterUpdatedAt.label"),
    },
    {
      name: "isBeforeUpdatedAt",
      label: t("transaction.filters.isBeforeUpdatedAt.label"),
    },
  ];
  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" alignItems="center">
        <TrackPressable action="New account statement">
          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={canCreateAccountStatements}
          >
            <LakeButton
              size="small"
              icon="add-circle-filled"
              onPress={openNew}
              color="current"
              disabled={!canCreateAccountStatements}
            >
              {t("accountStatements.new")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>

        <Space width={8} />

        <FilterChooser<keyof AccountStatementsFilters>
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          title={t("webhook.filters.choose")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh webhooks list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        {totalCount
          .map<ReactNode>(count => (
            <Tag size="large" color="partner">
              {t("accountStatements.counter", { count })}
            </Tag>
          ))
          .toNull()}
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </View>
  );
};
