import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { useEffect, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { CancelContractModal } from "../components/CardModals";
import { CardStatus, CardType, GetAccountMembershipCardsDocument } from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { Card, Edge, getCardHolderName } from "../utils/card";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { CardList, cardListDefaultColumns } from "./CardList";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type ExtraInfo = {
  projectId: string;
  projectEnv: ProjectEnv;
  onCancelCard: (card: Card) => void;
  reexecuteQuery: () => void;
  canCancelCardContracts: boolean;
};

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.CardDetailRoot({ projectId, projectEnv, cardId: id })} />
);

type Props = {
  accountMembershipId: string;
  params: RouteParams<"AccountMembershipsDetailCards">;
};

const PER_PAGE = 20;

const statusList = ["Canceled", "Canceling", "ConsentPending", "Enabled", "Processing"] as const;
const typeList = ["SingleUseVirtual", "Virtual", "VirtualAndPhysical"] as const;

export const AccountMembershipCards = ({ params, accountMembershipId }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const canCancelCardContracts = usePermissions(projectEnv).dataCard.write;

  const serializedStatus = JSON.stringify(params.statuses ?? null);
  const serializedType = JSON.stringify(params.types ?? null);

  const filters: CardFilters = useMemo(() => {
    const status = JSON.parse(serializedStatus) as string[] | null | undefined;
    const type = JSON.parse(serializedType) as string[] | null | undefined;

    return {
      statuses: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      types: isNotNullish(type)
        ? Array.filterMap(type, item =>
            match(item)
              .with(...typeList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [serializedType, serializedStatus]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountMembershipCardsDocument, {
    accountMembershipId,
    first: PER_PAGE,
    filters: { ...filters, search },
  });

  const [cancelCardInfo, setCancelCardInfo] = useState<Card | null>(null);

  const columns = useColumnChooser("AccountMembership>Cards", {
    defaultFixedColumns: cardListDefaultColumns.fixed,
    defaultActiveColumns: cardListDefaultColumns.active,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      reexecuteQuery: reload,
      onCancelCard: card => {
        setCancelCardInfo(card);
      },
      canCancelCardContracts,
    };
  }, [projectEnv, projectId, canCancelCardContracts, reload]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ accountMembership }) => Option.fromNullable(accountMembership?.cards.totalCount));

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountMembershipsDetailCards", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("AccountMembershipsDetailCards", { ...params, search });
        }}
      />

      <Space width={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accountMembership?.cards}>
            {cards => (
              <CardList
                cards={cards?.edges ?? []}
                columns={columns}
                onEndReached={() => {
                  if (cards?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: cards?.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("accountMembership.cards.empty")}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}

      <CancelContractModal
        canCancelCardContracts={canCancelCardContracts}
        visible={isNotNullish(cancelCardInfo)}
        cardId={cancelCardInfo?.id ?? ""}
        cardHolderName={getCardHolderName(cancelCardInfo).getOr("")}
        onClose={() => {
          setCancelCardInfo(null);
        }}
        onSuccess={() => {
          reload();
        }}
      />
    </>
  );
};

const statusFilter: FilterCheckboxDef<CardStatus> = {
  type: "checkbox",
  label: t("cards.list.status"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Canceled", label: t("cards.list.status.canceled") },
    { value: "Canceling", label: t("cards.list.status.canceling") },
    { value: "Enabled", label: t("cards.list.status.enabled") },
    { value: "ConsentPending", label: t("cards.list.status.consentPending") },
    { value: "Processing", label: t("cards.list.status.processing") },
  ],
};

const typeFilter: FilterCheckboxDef<CardType> = {
  type: "checkbox",
  label: t("cards.list.type"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "SingleUseVirtual", label: t("card.type.singleUseVirtual") },
    { value: "Virtual", label: t("card.type.virtual") },
    { value: "VirtualAndPhysical", label: t("card.type.virtualAndPhysical") },
  ],
};

const filtersDefinition = {
  statuses: statusFilter,
  types: typeFilter,
};

type CardFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: CardFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: CardFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const availableFilters: { name: keyof CardFilters; label: string }[] = useMemo(
    () => [
      {
        name: "statuses",
        label: t("cards.list.status"),
      },
      {
        name: "types",
        label: t("cards.list.type"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh memberships list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
