import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Array, Option } from "@swan-io/boxed";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { ColumnConfig } from "@swan-io/lake/src/components/VirtualizedList";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { usePersistedState } from "@swan-io/lake/src/hooks/usePersistedState";
import { forwardRef, ReactNode, useCallback, useMemo, useRef } from "react";
import {
  unstable_createElement as createElement,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { t } from "../utils/i18n";

const ACTIVE_TITLE_ID = "-";

const styles = StyleSheet.create({
  content: {
    minWidth: 200,
  },
  listContainer: {
    paddingHorizontal: spacings[24],
    paddingTop: spacings[12],
    paddingBottom: spacings[16],
  },
  title: {
    paddingVertical: spacings[8],
    userSelect: "none",
  },
  column: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: spacings[32], // sortable items must have the same height
  },
  text: {
    userSelect: "none",
  },
  active: {
    zIndex: 10,
  },
  handle: {
    cursor: "grab",
    paddingVertical: spacings[4],
  },
  grabbing: {
    cursor: "grabbing",
  },
});

type DivProps = {
  children?: ReactNode;
  className?: string;
  style?: StyleProp<ViewStyle>;
};

const Div = forwardRef<HTMLDivElement, DivProps>((props, forwardedRef) =>
  createElement("div", { ref: forwardedRef, ...props }),
);

type ColumnProps = {
  checked: boolean;
  disabled: boolean;
  id: string;
  onValueChange: (id: string) => void;
  title: string;
};

export type ColumnChooserConfig<T, ExtraInfo> = {
  fixed: ColumnConfig<T, ExtraInfo>[] | undefined;
  active: ColumnConfig<T, ExtraInfo>[];
  hidden: ColumnConfig<T, ExtraInfo>[];

  sortColumns: (columns: { fixed: string[]; active: string[] }) => void;
  hideColumn: (columnId: string) => void;
  showColumn: (columnId: string) => void;
};

const Column = ({ checked, disabled, id, onValueChange, title }: ColumnProps) => {
  const { active, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable(
    { id },
  );

  const isActive = active?.id === id;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Div ref={setNodeRef} style={[styles.column, isActive && styles.active, style]}>
      <Pressable
        aria-checked={checked}
        role="checkbox"
        disabled={disabled}
        onPress={() => {
          onValueChange(id);
        }}
      >
        <LakeCheckbox color="current" value={checked} disabled={disabled} />
      </Pressable>

      <Space width={12} />

      <LakeText color={colors.gray[900]} variant="smallRegular" style={styles.text}>
        {title}
      </LakeText>

      {checked && (
        <>
          <Fill minWidth={24} />

          <Div
            ref={setActivatorNodeRef}
            style={[styles.handle, isActive && styles.grabbing]}
            {...listeners}
          >
            <Icon name="re-order-regular" size={16} color={colors.gray[900]} />
          </Div>
        </>
      )}
    </Div>
  );
};

const ActiveTitle = () => {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: ACTIVE_TITLE_ID,
    disabled: true,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Div ref={setNodeRef} style={[styles.column, style]} {...listeners}>
      <LakeText variant="smallRegular">{t("common.columns.active")}</LakeText>
    </Div>
  );
};

export const useColumnChooser = <T, ExtraInfo>(
  id: string,
  {
    defaultFixedColumns,
    defaultActiveColumns,
  }: {
    defaultFixedColumns?: ColumnConfig<T, ExtraInfo>[];
    defaultActiveColumns: ColumnConfig<T, ExtraInfo>[];
  },
): ColumnChooserConfig<T, ExtraInfo> => {
  const defaultValue = useMemo<[fixed: string[], active: string[], hidden: string[]]>(() => {
    const fixed = defaultFixedColumns?.map(column => column.id) ?? [];
    const active = defaultActiveColumns.map(column => column.id);
    const hidden: string[] = []; // no hidden columns by default

    return [fixed, active, hidden];
  }, [defaultFixedColumns, defaultActiveColumns]);

  const [persistedState, setPersistedState] = usePersistedState(`columns:${id}`, defaultValue);

  const knownColumns = useMemo(() => {
    const array = [...(defaultFixedColumns ?? []), ...defaultActiveColumns];
    const map = new Map<string, ColumnConfig<T, ExtraInfo>>();

    for (const column of array) {
      map.set(column.id, column);
    }

    return { array, map };
  }, [defaultFixedColumns, defaultActiveColumns]);

  const { active, fixed, hidden } = useMemo<{
    fixed: ColumnConfig<T, ExtraInfo>[] | undefined;
    active: ColumnConfig<T, ExtraInfo>[];
    hidden: ColumnConfig<T, ExtraInfo>[];
  }>(() => {
    const [fixed, active, hidden] = persistedState;

    const storedIds = new Set([...fixed, ...active, ...hidden]);
    const fixedArray = Array.filterMap(fixed, id => Option.fromNullable(knownColumns.map.get(id)));

    return {
      fixed: fixedArray.length === 0 ? undefined : fixedArray, // don't return an empty array
      active: [
        ...Array.filterMap(active, id => Option.fromNullable(knownColumns.map.get(id))),
        ...knownColumns.array.filter(column => !storedIds.has(column.id)),
      ],
      hidden: Array.filterMap(hidden, id => Option.fromNullable(knownColumns.map.get(id))),
    };
  }, [knownColumns, persistedState]);

  return {
    fixed,
    active,
    hidden,

    sortColumns: useCallback(
      ({ fixed, active }) =>
        setPersistedState(([_prevFixed, _prevActive, prevHidden]) => [fixed, active, prevHidden]),
      [setPersistedState],
    ),

    hideColumn: useCallback(
      columnId =>
        setPersistedState(([prevFixed, prevActive, prevHidden]) => [
          prevFixed.filter(id => id !== columnId),
          prevActive.filter(id => id !== columnId),
          [...prevHidden, columnId],
        ]),
      [setPersistedState],
    ),

    showColumn: useCallback(
      columnId =>
        setPersistedState(([prevFixed, prevActive, prevHidden]) => [
          prevFixed.filter(id => id !== columnId),
          [...prevActive, columnId],
          prevHidden.filter(id => id !== columnId),
        ]),
      [setPersistedState],
    ),
  };
};

const ColumnList = <T, ExtraInfo>({
  fixed,
  active,
  hidden,

  sortColumns,
  showColumn,
  hideColumn,
}: ColumnChooserConfig<T, ExtraInfo>) => {
  const disabled = (fixed?.length ?? 0) + active.length <= 1;

  const items = useMemo(
    () => [...(fixed?.map(item => item.id) ?? []), ACTIVE_TITLE_ID, ...active.map(item => item.id)],
    [fixed, active],
  );

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (typeof active.id === "string" && typeof over?.id === "string" && active.id !== over.id) {
      const array = arrayMove(items, items.indexOf(active.id), items.indexOf(over.id));
      const activeTitleIndex = array.indexOf(ACTIVE_TITLE_ID);

      sortColumns({
        fixed: array.slice(0, activeTitleIndex),
        active: array.slice(activeTitleIndex + 1),
      });
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.content}>
      <View style={styles.listContainer}>
        <LakeText variant="smallRegular" style={styles.title}>
          {t("common.columns.fixed")}
        </LakeText>

        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToParentElement, restrictToVerticalAxis]}
          onDragEnd={handleOnDragEnd}
        >
          <SortableContext items={items}>
            {fixed?.map(({ id, title }) => (
              <Column
                checked={true}
                disabled={disabled}
                id={id}
                key={id}
                onValueChange={hideColumn}
                title={title}
              />
            ))}

            <ActiveTitle />

            {active.map(({ id, title }) => (
              <Column
                checked={true}
                disabled={disabled}
                id={id}
                key={id}
                onValueChange={hideColumn}
                title={title}
              />
            ))}
          </SortableContext>
        </DndContext>
      </View>

      {hidden.length > 0 && (
        <>
          <Separator />

          <View style={styles.listContainer}>
            <LakeText variant="smallRegular" style={styles.title}>
              {t("common.columns.hidden")}
            </LakeText>

            {hidden.map(({ id, title }) => (
              <Column
                checked={false}
                disabled={false}
                id={id}
                key={id}
                onValueChange={showColumn}
                title={title}
              />
            ))}
          </View>
        </>
      )}
    </ScrollView>
  );
};

export const ColumnChooser = <T, ExtraInfo>(props: ColumnChooserConfig<T, ExtraInfo>) => {
  const inputRef = useRef<View>(null);
  const [visible, { close, toggle }] = useDisclosure(false);

  return (
    <>
      <LakeButton
        ariaLabel={t("common.columns")}
        size="small"
        mode="secondary"
        color="gray"
        onPress={toggle}
        ref={inputRef}
        icon="chevron-down-filled"
        iconPosition="end"
      >
        {t("common.columns")}
      </LakeButton>

      <Popover
        matchReferenceMinWidth={true}
        onDismiss={close}
        referenceRef={inputRef}
        returnFocus={false}
        role="listbox"
        visible={visible}
      >
        <ColumnList {...props} />
      </Popover>
    </>
  );
};
