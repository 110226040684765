import { AsyncData, Future, Result } from "@swan-io/boxed";
import { ClientError, useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { RightPanel } from "@swan-io/lake/src/components/RightPanel";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { match, P } from "ts-pattern";
import {
  NotificationDetailsDocument,
  NotificationDetailsQuery,
  NotificationSettingsDocument,
  UpdateNotificationSettingsDocument,
  type UpdateNotificationSettingsInput,
} from "../graphql/exposed-internal";
import { formatNestedMessage, t } from "../utils/i18n";
import { RouteParams, Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { NotificationsPhonePreview } from "./NotificationsPhonePreview";

const styles = StyleSheet.create({
  lineDescription: {
    fontStyle: "italic",
  },
  details: {
    paddingHorizontal: spacings[40],
  },
  content: {
    paddingTop: spacings[24],
    paddingBottom: spacings[12],
    paddingHorizontal: spacings[40],
  },
  text: { fontStyle: "italic" },
});

type LineProps = {
  isUpdating: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  name: string;
  description?: string;
  onPressLine: () => void;
};

const UNKNOWN_VALUE = <LakeText style={styles.text}>{t("common.unknown")}</LakeText>;

const formatNotificationTitle = (name: string) => {
  return match(name)
    .with("cardInsufficientFunds", () =>
      t("projectSettings.notifications.rejectedCardTransaction.insufficientFunds"),
    )
    .with("cardPermanentlyBlocked", () =>
      t("projectSettings.notifications.rejectedCardTransaction.cardPermanentlyBlocked"),
    )
    .with("cardSpendingLimitReached", () =>
      t("projectSettings.notifications.rejectedCardTransaction.cardSpendingLimitReached"),
    )
    .otherwise(() => null);
};

const formatNotificationDescription = (name: string) => {
  return match(name)
    .with("cardInsufficientFunds", () =>
      t(
        "projectSettings.notifications.rejectedCardTransaction.insufficientFunds.sidePanelDescription",
      ),
    )
    .with("cardPermanentlyBlocked", () =>
      t(
        "projectSettings.notifications.rejectedCardTransaction.cardPermanentlyBlocked.sidePanelDescription",
      ),
    )
    .with("cardSpendingLimitReached", () =>
      t(
        "projectSettings.notifications.rejectedCardTransaction.cardSpendingLimitReached.sidePanelDescription",
      ),
    )
    .otherwise(() => null);
};

const NotificationSettingsLine = ({
  isUpdating,
  value,
  name,
  description,
  onPressLine,
  onChange,
}: LineProps) => {
  return (
    <Pressable onPress={() => onPressLine()}>
      <Box direction="row" alignItems="center">
        <Switch disabled={isUpdating} value={value} onValueChange={onChange} />
        <Space width={12} />

        <Box grow={1} shrink={1}>
          <LakeText color={colors.gray[900]} variant="smallRegular">
            {name}
          </LakeText>

          {description != null ? (
            <LakeText
              color={colors.gray[500]}
              variant="smallRegular"
              style={styles.lineDescription}
            >
              {description}
            </LakeText>
          ) : null}
        </Box>

        <Space width={12} />

        <LakeTooltip content={t("projectSettings.notifications.tooltip.sms")}>
          <Tag color={value ? "positive" : "gray"} icon="phone-regular" />
        </LakeTooltip>

        <Space width={12} />
        <Icon size={20} name="chevron-right-filled" color={colors.gray[500]} />
      </Box>
    </Pressable>
  );
};

type SettingsPanelProps = {
  visible: boolean;
  onPressClose: () => void;
  isUpdating: boolean;
  switchValue: boolean;
  onSwitchChange: (
    value: boolean,
    refresh: () => Future<Result<NotificationDetailsQuery, ClientError>>,
  ) => void;
  notificationName: string;
};

const SettingsPanel = ({
  visible,
  onPressClose,
  isUpdating,
  switchValue,
  onSwitchChange,
  notificationName,
}: SettingsPanelProps) => {
  const [data, { refresh }] = useQuery(NotificationDetailsDocument, {
    notificationName,
    periodEnd: dayjs.utc().format("YYYY-MM-DDT00:00:00.000+01:00"),
    periodStart: dayjs.utc().subtract(30, "days").format("YYYY-MM-DDT00:00:00.000+01:00"),
  });

  const [languagePreview, setLanguagePreview] = useState<string>("en");

  return (
    <RightPanel visible={visible} onPressClose={onPressClose}>
      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ notificationDetails }) => {
          const languages = notificationDetails?.translations.map(translation => ({
            name: match(translation.language)
              .with("de", () => t("accountMembership.language.de"))
              .with("en", () => t("accountMembership.language.en"))
              .with("es", () => t("accountMembership.language.es"))
              .with("fr", () => t("accountMembership.language.fr"))
              .with("it", () => t("accountMembership.language.it"))
              .with("nl", () => t("accountMembership.language.nl"))
              .with("pt", () => t("accountMembership.language.pt"))
              .with("fi", () => t("accountMembership.language.fi"))
              .otherwise(() => t("common.unknown")),
            value: translation.language,
          }));

          return (
            <ScrollView>
              <Box direction="row" justifyContent="spaceBetween" style={styles.content}>
                <LakeButton
                  mode="tertiary"
                  icon="lake-close"
                  ariaLabel={t("common.close")}
                  onPress={onPressClose}
                />
              </Box>

              <Box direction="column" style={styles.details}>
                <Icon size={32} name="payment-regular" color={colors.current.primary} />
                <Space height={8} />

                <LakeHeading level={1} variant="h3">
                  {formatNotificationTitle(notificationName)}
                </LakeHeading>

                <Space height={4} />
                <LakeText>{formatNotificationDescription(notificationName)}</LakeText>
                <Space height={12} />

                <Box direction="row">
                  <Switch
                    disabled={isUpdating}
                    value={switchValue}
                    onValueChange={value => onSwitchChange(value, refresh)}
                  />

                  <Space width={12} />

                  <LakeText color={colors.gray[900]}>
                    {formatNestedMessage("projectSettings.notifications.activateNotification", {
                      italic: text => (
                        <LakeText style={styles.text} color={colors.gray[500]}>
                          {text}
                        </LakeText>
                      ),
                    })}
                  </LakeText>
                </Box>
              </Box>

              <Space height={12} />
              <Separator />
              <Space height={12} />

              <Box direction="column" style={styles.details}>
                <ReadOnlyFieldList>
                  {match({ notificationDetails, switchValue })
                    .with(
                      {
                        notificationDetails: { lastActivated: P.select(P.nonNullable) },
                        switchValue: true,
                      },
                      () => (
                        <>
                          <LakeLabel
                            type="view"
                            label={t(
                              "projectSettings.notifications.rejectedCardTransaction.detail.lastTurnedOn",
                            )}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {isNotNullish(notificationDetails?.lastActivated)
                                  ? dayjs(notificationDetails?.lastActivated).format("LLL")
                                  : UNKNOWN_VALUE}
                              </LakeText>
                            )}
                          />
                        </>
                      ),
                    )
                    .with(
                      {
                        notificationDetails: { lastActivated: P.nullish },
                        switchValue: false,
                      },
                      () => null,
                    )
                    .with(
                      {
                        notificationDetails: { lastActivated: P.nonNullable },
                        switchValue: false,
                      },
                      () => (
                        <>
                          <LakeLabel
                            type="view"
                            label={t(
                              "projectSettings.notifications.rejectedCardTransaction.detail.lastTurnedOff",
                            )}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {dayjs(notificationDetails?.lastDeactivated).format("LLL")}
                              </LakeText>
                            )}
                          />
                        </>
                      ),
                    )
                    .otherwise(() => null)}

                  <LakeLabel
                    type="view"
                    label={t(
                      "projectSettings.notifications.rejectedCardTransaction.detail.totalNotificationsSent",
                    )}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {notificationDetails?.totalSentInPeriod}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t(
                      "projectSettings.notifications.rejectedCardTransaction.detail.preview",
                    )}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {t(
                          "projectSettings.notifications.rejectedCardTransaction.detail.previewDescription",
                        )}
                      </LakeText>
                    )}
                  />
                </ReadOnlyFieldList>

                <Space height={12} />

                <LakeSelect
                  placeholder={t(
                    "projectSettings.notifications.rejectedCardTransaction.detail.previewLanguage",
                  )}
                  items={languages ?? []}
                  value={languagePreview}
                  onValueChange={setLanguagePreview}
                />

                <Space height={48} />

                <NotificationsPhonePreview
                  text={
                    notificationDetails?.translations.find(
                      item => item.language === languagePreview,
                    )?.translatedContent ?? ""
                  }
                />

                <Space height={24} />
              </Box>
            </ScrollView>
          );
        })
        .exhaustive()}
    </RightPanel>
  );
};

export const NotificationSettingsPage = ({
  params,
}: {
  params: RouteParams<"SettingsNotifications">;
}) => {
  const [data, { isLoading, refresh }] = useQuery(NotificationSettingsDocument, {});

  const [updateNotificationSettings, notificationSettingsUpdate] = useMutation(
    UpdateNotificationSettingsDocument,
    {},
  );

  const updateSettings = (input: UpdateNotificationSettingsInput) =>
    updateNotificationSettings({ input })
      .mapOkToResult(filterRejectionsToResult)
      .tapError(error => showToast({ variant: "error", title: translateError(error), error }))
      .tapOk(() => {
        refresh();
      });

  // We need to wait for both because there's no cachable key given the shape of the API
  const isUpdating = isLoading || notificationSettingsUpdate.isLoading();

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <TileGridPlaceholder withTabs={false} numberOfItems={1} />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ notificationSettings }) => {
      return (
        <>
          <TileGrid>
            <Tile
              icon={<Icon size={28} name="payment-regular" color={colors.current.primary} />}
              title={t("projectSettings.notifications.rejectedCardTransaction")}
              description={t("projectSettings.notifications.rejectedCardTransaction.description")}
            >
              <NotificationSettingsLine
                onPressLine={() => {
                  Router.push("SettingsNotifications", {
                    ...params,
                    name: "cardInsufficientFunds",
                  });
                }}
                isUpdating={isUpdating}
                value={notificationSettings?.cardInsufficientFunds?.sms ?? false}
                onChange={sms => {
                  updateSettings({ cardInsufficientFunds: { sms } });
                }}
                name={t("projectSettings.notifications.rejectedCardTransaction.insufficientFunds")}
                description={t(
                  "projectSettings.notifications.rejectedCardTransaction.insufficientFunds.description",
                )}
              />

              <Separator space={12} />

              <NotificationSettingsLine
                onPressLine={() => {
                  Router.push("SettingsNotifications", {
                    ...params,
                    name: "cardSpendingLimitReached",
                  });
                }}
                isUpdating={isUpdating}
                value={notificationSettings?.cardSpendingLimitReached?.sms ?? false}
                onChange={sms => {
                  updateSettings({ cardSpendingLimitReached: { sms } });
                }}
                name={t(
                  "projectSettings.notifications.rejectedCardTransaction.cardSpendingLimitReached",
                )}
              />

              <Separator space={12} />

              <NotificationSettingsLine
                onPressLine={() => {
                  Router.push("SettingsNotifications", {
                    ...params,
                    name: "cardPermanentlyBlocked",
                  });
                }}
                isUpdating={isUpdating}
                value={notificationSettings?.cardPermanentlyBlocked?.sms ?? false}
                onChange={sms => {
                  updateSettings({ cardPermanentlyBlocked: { sms } });
                }}
                name={t(
                  "projectSettings.notifications.rejectedCardTransaction.cardPermanentlyBlocked",
                )}
              />
            </Tile>
          </TileGrid>

          <SettingsPanel
            visible={params.name != null}
            notificationName={params.name ?? ""}
            isUpdating={isUpdating}
            onPressClose={() => {
              const { name, ...otherParams } = params;
              Router.push("SettingsNotifications", otherParams);
            }}
            switchValue={
              params.name != null ? (notificationSettings?.[params.name]?.sms ?? false) : false
            }
            onSwitchChange={(sms, refresh) => {
              if (params.name != null) {
                updateSettings({ [params.name]: { sms } }).tapOk(() => {
                  refresh();
                });
              }
            }}
          />
        </>
      );
    })
    .exhaustive();
};
