import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import { GetCapitalDepositDocument, GetCapitalDepositQuery } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router, capitalDepositDetailRoutes } from "../utils/routes";
import { CapitalDepositDetailGeneral } from "./CapitalDepositDetailGeneral";
import { CapitalDepositShareholderArea } from "./CapitalDepositShareholderArea";
import { ColumnChooser, useColumnChooser } from "./ColumnChooser";
import { ErrorView } from "./ErrorView";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  capitalDepositId: string;
};

type ShareholderType = NonNullable<
  GetCapitalDepositQuery["capitalDepositCase"]
>["shareholders"][number];

const keyExtractor = ({ id }: ShareholderType) => id;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId, capitalDepositId },
}: LinkConfig<ShareholderType, ExtraInfo>) => (
  <Link
    to={Router.CapitalDepositDetailShareholderRoot({
      projectId,
      projectEnv,
      capitalDepositId,
      shareholderId: id,
    })}
  />
);

const defaultFixedColumns: ColumnConfig<ShareholderType, ExtraInfo>[] = [
  {
    width: 240,
    id: "name",
    title: t("capitalDeposit.shareholders.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { info } }) =>
      match(info)
        .with({ __typename: "CompanyShareholder" }, ({ name }) => (
          <TextCell variant="medium" text={name} />
        ))
        .with({ __typename: "IndividualShareholder" }, ({ firstName, lastName }) => (
          <TextCell variant="medium" text={`${firstName} ${lastName}`} />
        ))
        .exhaustive(),
  },
];

const defaultActiveColumns: ColumnConfig<ShareholderType, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("capitalDeposit.shareholders.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id } }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 400,
    id: "onboarding",
    title: t("capitalDeposit.shareholders.onboardingId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { onboarding }, extraInfo: { projectId, projectEnv } }) => {
      if (isNotNullish(onboarding)) {
        const onboardingId = onboarding.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("OnboardingDetailRoot", { projectId, projectEnv, onboardingId })
            }
          >
            {onboarding.id}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 220,
    id: "status",
    title: t("capitalDeposit.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { status } }) => (
      <Cell>
        {match(status)
          .with("CapitalFundsWiredToNotary", () => (
            <Tag color="positive">
              {t("capitalDeposit.shareholders.status.capitalFundsWiredToNotary")}
            </Tag>
          ))
          .with("CapitalTransferred", () => (
            <Tag color="shakespear">
              {t("capitalDeposit.shareholders.status.capitalTransferred")}
            </Tag>
          ))
          .with("PendingOnboarding", () => (
            <Tag color="gray">{t("capitalDeposit.shareholders.status.pendingOnboarding")}</Tag>
          ))
          .with("WaitingForTransfer", () => (
            <Tag color="warning">{t("capitalDeposit.shareholders.status.waitingForTransfer")}</Tag>
          ))
          .with("WaitingForVerification", () => (
            <Tag color="warning">
              {t("capitalDeposit.shareholders.status.waitingForVerification")}
            </Tag>
          ))
          .with("CapitalDepositCanceled", () => (
            <Tag color="gray">{t("capitalDeposit.shareholders.status.capitalDepositCanceled")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 250,
    id: "accountId",
    title: t("capitalDeposit.shareholders.accountId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { accountId }, extraInfo: { projectId, projectEnv } }) => {
      if (isNotNullish(accountId)) {
        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {accountId}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 240,
    id: "amount",
    title: t("capitalDeposit.amount"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { capitalDepositAmount } }) => (
      <TextCell
        text={
          capitalDepositAmount.value !== ""
            ? formatCurrency(Number(capitalDepositAmount.value), capitalDepositAmount.currency)
            : "-"
        }
      />
    ),
  },
  {
    width: 200,
    id: "uploadedDocuments",
    title: t("capitalDeposit.uploadedDocuments"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { documents } }) => {
      let numberOfValidatedDocuments = 0;
      documents.map(document => {
        if (
          document.statusInfo.status === "Validated" ||
          document.statusInfo.status === "Uploaded"
        ) {
          ++numberOfValidatedDocuments;
        }
      });
      return <TextCell text={String(numberOfValidatedDocuments)} />;
    },
  },
  {
    width: 200,
    id: "createdAt",
    title: t("capitalDeposit.createdAt"),
    renderTitle: ({ title }) => {
      return <HeaderCell text={title} />;
    },
    renderCell: ({ item: { createdAt } }) => (
      <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("capitalDeposit.updatedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { updatedAt } }) => (
      <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

type Props = {
  capitalDepositId: string;
};

export const CapitalDepositDetailArea = ({ capitalDepositId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(capitalDepositDetailRoutes);

  const [capitalDeposit, { reload }] = useQuery(GetCapitalDepositDocument, {
    id: capitalDepositId,
  });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const columns = useColumnChooser("CapitalDeposit>Shareholder", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const companyName = capitalDeposit
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ capitalDepositCase }) => Option.fromNullable(capitalDepositCase?.companyName))
    .getOr("");

  useCrumb(
    useMemo(
      () =>
        isNotNullish(capitalDeposit)
          ? {
              label: companyName,
              link: Router.CapitalDepositDetailRoot({
                projectId,
                projectEnv,
                capitalDepositId,
              }),
            }
          : undefined,
      [capitalDeposit, capitalDepositId, companyName, projectEnv, projectId],
    ),
  );

  const shouldRedirect = match(capitalDeposit)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ capitalDepositCase }) =>
      isNullish(capitalDepositCase),
    )
    .otherwise(() => false);

  if (shouldRedirect) {
    Router.push("CapitalDepositList", { projectId, projectEnv });
    return null;
  }

  const tabs = (
    <TabView
      tabs={[
        {
          label: t("capitalDeposit.tabs.general"),
          url: Router.CapitalDepositDetailRoot({ projectId, projectEnv, capitalDepositId }),
        },
        {
          label: t("capitalDeposit.tabs.shareholders"),
          url: Router.CapitalDepositDetailShareholdersList({
            projectId,
            projectEnv,
            capitalDepositId,
          }),
        },
      ]}
      otherLabel={t("common.tabs.other")}
    />
  );

  return match(route)
    .with({ name: "CapitalDepositDetailRoot" }, () => (
      <>
        {tabs}

        <Space height={24} />

        {match(capitalDeposit.mapOk(data => data.capitalDepositCase))
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <TileGridPlaceholder withTabs={false} />
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
          .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <NotFoundPage />)
          .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), capitalDepositCase => {
            return (
              <LakeScrollView>
                <CapitalDepositDetailGeneral capitalDeposit={capitalDepositCase} />
              </LakeScrollView>
            );
          })
          .exhaustive()}
      </>
    ))
    .with({ name: "CapitalDepositDetailShareholdersList" }, () => (
      <View style={commonStyles.fill}>
        {tabs}

        <Space height={24} />

        <Box direction="row" alignItems="center">
          <ColumnChooser {...columns} />
          <Space width={8} />

          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              reload().tap(() => setIsRefreshing(false));
            }}
          />
        </Box>

        <Space height={12} />

        {match(capitalDeposit.mapOk(data => data.capitalDepositCase))
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <VirtualizedListPlaceholder
              headerHeight={48}
              rowHeight={48}
              count={20}
              marginHorizontal={negativeSpacings[24]}
            />
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
          .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <NotFoundPage />)
          .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), capitalDepositCase => (
            <VirtualizedList
              variant="default"
              marginHorizontal={negativeSpacings[24]}
              extraInfo={{
                projectEnv,
                projectId,
                capitalDepositId,
              }}
              keyExtractor={keyExtractor}
              data={capitalDepositCase.shareholders}
              stickedToStartColumns={columns.fixed}
              columns={columns.active}
              headerHeight={48}
              rowHeight={48}
              getRowLink={getRowLink}
              renderEmptyList={() => (
                <EmptyView
                  icon="lake-inbox-empty"
                  title={t("capitalDeposit.shareholders.emptyListTitle")}
                />
              )}
            />
          ))
          .exhaustive()}
      </View>
    ))
    .with({ name: "CapitalDepositDetailShareholderArea" }, ({ params }) => (
      <CapitalDepositShareholderArea
        shareholderId={params.shareholderId}
        capitalDepositId={capitalDepositId}
      />
    ))
    .otherwise(() => <NotFoundPage />);
};
