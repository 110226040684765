import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useCallback, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { ColumnChooser, useColumnChooser } from "../components/ColumnChooser";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import { WebhookDeleteAction } from "../components/WebhookDeleteAction";
import { WebhookSubscriptionsDocument, WebhookSubscriptionsQuery } from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router, webhooksRoutes } from "../utils/routes";
import { NewWebhookSubscriptionPage } from "./NewWebhookSubscriptionPage";

type Edge = GetEdge<WebhookSubscriptionsQuery["webhookSubscriptions"]>;

const keyExtractor = ({ node: { id } }: Edge) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  reexecuteQuery: () => void;
  canEditWebhooks: boolean;
};

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link
    to={Router.DevelopersWebhooksSubscriptionRoot({
      projectId,
      projectEnv,
      webhookSubscriptionId: id,
    })}
  />
);

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 280,
    id: "name",
    title: t("webhookEvent.table.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }) => <TextCell variant="medium" text={label} />,
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 500,
    grow: true,
    id: "entrypoint",
    title: t("webhook.info.entrypointUrl"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { endpoint },
      },
    }) => (
      <CopyableTextCell
        text={endpoint}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 110,
    id: "status",
    title: t("webhooks.table.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }) => (
      <Cell>
        {match(status)
          .with("Enabled", () => <Tag color="positive">{status}</Tag>)
          .with("Disabled", () => <Tag color="gray">{status}</Tag>)
          .with("Broken", () => <Tag color="negative">{status}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
];

const endColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 40,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({
      extraInfo: { reexecuteQuery, canEditWebhooks },
      item: {
        node: { id, label },
      },
    }) => (
      <Cell align="right">
        <LakeTooltip
          placement="right"
          content={t("common.action.denied")}
          disabled={canEditWebhooks}
        >
          <WebhookDeleteAction
            webhookId={id}
            webhookName={label}
            onSuccess={() => {
              reexecuteQuery();
            }}
          >
            {({ onPress }) => (
              <TrackPressable action="Delete webhook">
                <Pressable
                  aria-label={t("webhooks.deleteWebhook")}
                  onPress={event => {
                    event.preventDefault();
                    onPress();
                  }}
                  role="button"
                  disabled={!canEditWebhooks}
                >
                  {({ hovered }) => (
                    <Icon
                      color={hovered ? colors.negative[400] : colors.gray[200]}
                      name="delete-regular"
                      size={16}
                    />
                  )}
                </Pressable>
              </TrackPressable>
            )}
          </WebhookDeleteAction>
        </LakeTooltip>
      </Cell>
    ),
  },
];

export const WebhookSubscriptionListPage = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditWebhooks = usePermissions(projectEnv).webhook.write;

  const route = Router.useRoute(webhooksRoutes);
  const [data, { reload, refresh }] = useQuery(WebhookSubscriptionsDocument, {});

  const openNew = useCallback(() => {
    Router.push("DevelopersWebhooksNew", { projectId, projectEnv });
  }, [projectEnv, projectId]);

  const columns = useColumnChooser("Webhooks", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      reexecuteQuery: refresh,
      canEditWebhooks,
    };
  }, [canEditWebhooks, projectEnv, projectId, refresh]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const shouldShowTopbar = data
    .toOption()
    .flatMap(result =>
      result.map(result => result.webhookSubscriptions.edges.length > 0).toOption(),
    )
    .getOr(true);

  return (
    <>
      {shouldShowTopbar && (
        <Box direction="row" alignItems="start">
          <TrackPressable action="New webhook">
            <LakeTooltip
              placement="right"
              content={t("common.action.denied")}
              disabled={canEditWebhooks}
            >
              <LakeButton
                size="small"
                icon="add-circle-filled"
                onPress={openNew}
                color="current"
                disabled={!canEditWebhooks}
              >
                {t("common.new")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>

          <Space width={8} />
          <ColumnChooser {...columns} />
          <Space width={8} />

          <TrackPressable action="Refresh webhooks list">
            <LakeButton
              size="small"
              mode="secondary"
              icon="arrow-counterclockwise-filled"
              loading={isRefreshing}
              onPress={() => {
                setIsRefreshing(true);
                reload().tap(() => setIsRefreshing(false));
              }}
              color="gray"
              ariaLabel={t("webhooks.refresh")}
            />
          </TrackPressable>
        </Box>
      )}

      <Space height={24} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <VirtualizedList
            variant="default"
            marginHorizontal={negativeSpacings[24]}
            data={data.webhookSubscriptions.edges}
            keyExtractor={keyExtractor}
            extraInfo={extraInfo}
            stickedToStartColumns={columns.fixed}
            columns={columns.active}
            stickedToEndColumns={endColumns}
            headerHeight={48}
            rowHeight={48}
            getRowLink={getRowLink}
            renderEmptyList={() => (
              <EmptyView
                icon="lake-inbox-empty"
                title={t("webhooks.table.empty")}
                subtitle={t("webhooks.table.emptySuggestion")}
              >
                <LakeButtonGroup justifyContent="center">
                  <TrackPressable action="New webhook">
                    <LakeTooltip
                      placement="right"
                      content={t("common.action.denied")}
                      disabled={canEditWebhooks}
                    >
                      <LakeButton
                        size="small"
                        icon="add-circle-filled"
                        onPress={openNew}
                        color="current"
                        disabled={!canEditWebhooks}
                      >
                        {t("common.new")}
                      </LakeButton>
                    </LakeTooltip>
                  </TrackPressable>
                </LakeButtonGroup>
              </EmptyView>
            )}
          />
        ))
        .exhaustive()}

      <LakeModal
        visible={match(route)
          .with({ name: "DevelopersWebhooksNew" }, () => true)
          .otherwise(() => false)}
        color="current"
        icon="add-circle-regular"
        title={t("newWebhook.title")}
      >
        <NewWebhookSubscriptionPage
          canEditWebhooks={canEditWebhooks}
          onSave={() => {
            refresh();
          }}
        />
      </LakeModal>
    </>
  );
};
